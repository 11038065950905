import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { Location } from "@reach/router"
import { graphql, Link } from "gatsby"
import Button from "@material-ui/core/Button"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import KeymanItem from "../components/common/keyman-item"
import Pagination from "../components/common/pagination"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import BreadcrumbList from "../components/common/breadcrumb-list"
import RankCard from "../components/common/rank-card"
import Building from "../components/icons/building"

import { addCommas, getQueryVariable } from "../utils/str"
import { INDUSTRIES_PER_PAGE } from "../constants/constants"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"

export const query = graphql`
  query allindustriesJson($slug: String) {
    industriesJson(fields: { slug: { eq: $slug } }) {
      value
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.industry.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.industry.pageInfo_keyman,
    isLoadingKeymans: state.industry.isLoadingKeymans,
    industry: state.industry.industry,
    isLoadingIndustryRank: state.industry.isLoadingJsicLRank,
    rankStats: state.industry.jsicLRankStats,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.industry.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getIndustriesById: id => dispatch(actions.getIndustriesById(id)),
    getRankJsicLById: id => dispatch(actions.getRankJsicLById(id, null)),
  }
}

const styles = theme => ({
  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },

  button: {
    color: "#5A5B62",
    background: "#FFFFFF",
    fontSize: "12px",
    padding: "4px 10px",
    whiteSpace: "nowrap",
    marginBottom: "18px",
    fontWeight: "bold",
    marginLeft: "auto",
  },

  flexBox: {
    display: "flex",
  },
})

class Industry extends React.Component {
  componentDidMount() {
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
    this.props.getIndustriesById(this.props.data.industriesJson.value)
    this.props.getKeymans(this.getQuery(this.props), true)
    this.props.getRankJsicLById(this.props.data.industriesJson.value)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoadingKeymans && prevProps.isLoadingKeymans) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getKeymans(this.getQuery(this.props))
    }
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: INDUSTRIES_PER_PAGE,
      page: 1,
      jsic_l: [props.data.industriesJson.value],
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      classes,
      data,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      industry,
      pageInfo_keyman,
      isLoadingKeymans,
      rankStats,
      isInitialized,
    } = this.props

    const keymans =
      (!isInitialized) ||
      (isLoadingKeymans && this.props.keymans && this.props.keymans.length === 0)
        ? Array.from(Array(INDUSTRIES_PER_PAGE))
        : this.props.keymans

    return (
      <Layout hasFooterPopup={true}>
        <SEO
          title={`${data.industriesJson.value}の決裁者一覧`}
          description={`${data.industriesJson.value}の決裁者一覧です。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
          canonical={`https://keyman-db.smart-letter.com/industries/${this.props.data.industriesJson.value}`}
        />
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                  { path: "/industries", text: "企業の業界一覧" },
                ]}
                current={{
                  path: `/industries/${this.props.data.industriesJson.value}`,
                  text: `${data.industriesJson.value}の決裁者一覧`,
                }}
              />
              <div>
                <h2 className="title">
                  {data.industriesJson.value}の決裁者一覧
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>

              <RankCard
                id="industry-rank"
                type="業界"
                name={data.industriesJson.value}
                data={rankStats[data.industriesJson.value]}
              />

              {keymans.length > 0 && (
                <div className={classes.flexBox}>
                <p className={classes.subheading}>
                  {addCommas(industry ? industry.count : " - ")}
                  人の決裁者が見つかりました
                </p>
                <Button className={classes.button} component={Link}
                  to={`/industries/${this.props.data.industriesJson.value}/departments`}
                  variant="contained">
                  <Building color='secondary'/>部門で絞り込む
                </Button>
              </div>
              )}

              {keymans.length === 0 ? (
                <div>決裁者がありません。</div>
              ) : (
                <div className="list">
                  {keymans.map((item, i) => (
                    <KeymanItem
                      item={item}
                      key={i}
                      showIndustry={false}
                      showDepartment={true}
                      showBorderBottom={i !== keymans.length - 1}
                    />
                  ))}
                </div>
              )}

              {keymans.length > 0 && pageInfo_keyman ? (
                <Pagination
                  currentPage={pageInfo_keyman.current_page}
                  lastPage={pageInfo_keyman.last_page}
                  perPage={INDUSTRIES_PER_PAGE}
                  path={`industries/${this.props.data.industriesJson.value}`}
                  total={pageInfo_keyman.total}
                  onChange={(params, overwrite) =>
                    this.props.getKeymans(
                      this.getQuery(this.props, params),
                      overwrite
                    )
                  }
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>

            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Industry {...locationProps} {...props} />}
    </Location>
  ))
)
